import { createMiddleware } from 'redux-beacon';
import debounceEvent from '@redux-beacon/debounce-event';


// https://rangle.gitbook.io/redux-beacon/getting-started-redux-users#step-3
// https://rangle.gitbook.io/redux-beacon/index-1/events-map
const eventsMap = {
  // https://rangle.gitbook.io/redux-beacon/index-1/event-definition
  // https://rangle.gitbook.io/redux-beacon/index-3/debounce-event
  '@@router/LOCATION_CHANGE': debounceEvent(100, (action) => ({
    type: 'ga4_page_view',
    page_location: action.payload.location.pathname
  })),

  'LOG_ERROR': (action) => ({
    type: 'ga4_event',
    event_name: 'validation_error',
    event_params: {
      page_location: action.path,
      fieldName: action.fieldName,
      errorName: action.errorName,
    }
  }),

  'ui/setCookieConsent': (action) => ({
    type: 'ga4_consent',
    consent_arg: 'update',
    consent_params: {
      analytics_storage: (action.payload.settings.analytics
        ? 'granted'
        : 'denied'
      )
    }
  })
};

// https://rangle.gitbook.io/redux-beacon/examples-and-recipes#how-to-create-your-own-target
let previous_page_location;

function googleAnalytics4Target(events) {
  events.forEach(event => {
    switch (event.type) {

      // https://developers.google.com/analytics/devguides/collection/ga4/single-page-applications?implementation=event
      case 'ga4_page_view':
        window && window.gtag && window.gtag('config', window.gtag._id, {
          'send_page_view': false,
          'page_referrer': previous_page_location,
          'page_location': event.page_location,
          'update': true,
        });
        window && window.gtag && window.gtag('event', 'page_view');
        previous_page_location = event.page_location;
        break;

      // https://developers.google.com/tag-platform/gtagjs/reference#event
      case 'ga4_event':
        window && window.gtag && window.gtag('event', event.event_name, event.event_params);
        break;

      // https://developers.google.com/tag-platform/gtagjs/reference#consent
      case 'ga4_consent':
        window && window.gtag && window.gtag('consent', event.consent_arg, event.consent_params);

        if (event.consent_params.analytics_storage === 'denied') {
          // Delete existing Google Analytics cookies
          // See https://support.google.com/analytics/answer/11397207?hl=en
          window.gtag(
            'get',
            window.gtag._id,
            'cookie_domain',
            current_cookie_domain =>
              window?.document?.cookie?.split(';')
              .map(cookieNameValue =>
                cookieNameValue.trim().split('=')[0]
              )
              .filter(cookieName =>
                cookieName.startsWith('_ga')
              )
              .forEach(gaCookieName =>
                [
                  current_cookie_domain,
                  '.service.gov.uk',
                  '.homeoffice.gov.uk',
                ].forEach( (cookieDomain) =>
                  window.document.cookie = `${gaCookieName}=; path=/; domain=${cookieDomain}; expires=Thu, 01 Jan 1970 00:00:01 GMT`
                )
              )
          );
        }

        break;

      default:
        break;
    }
  });
};

// https://rangle.gitbook.io/redux-beacon/getting-started-redux-users#step-3
const googleAnalytics4Middleware = createMiddleware(eventsMap, googleAnalytics4Target);


export {googleAnalytics4Middleware};
