import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    loading: {
      global: false
    },
    cookieConsent: {
      settings: undefined,
      didJustSet: false,
    }
  },
  reducers: {
    toggleLoadingFlag: (state, action) => {
      state.loading = {
        ...state.loading,
        [action.payload]: !state.loading[action.payload],
      }
    },
    setCookieConsent: (state, action) => {
      state.cookieConsent = {
        ...state.cookieConsent,
        settings: action.payload.settings,
        didJustSet: true
      };
    },
    clearDidJustSet: (state) => {
      state.cookieConsent = {
        ...state.cookieConsent,
        didJustSet: false
      };
    }
  }
});

export const { toggleLoadingFlag, setCookieConsent, clearDidJustSet } = uiSlice.actions;
export default uiSlice.reducer;
