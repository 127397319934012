import { initialStatusState } from "../features/status/statusSlice";

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    // TODO: maintain status state
    //reset state using initialStates to prevent saving that particular slice
    const statetoSave = {
      ...state,
      status: initialStatusState.status
    }
    const serializedState = JSON.stringify(statetoSave);
    sessionStorage.setItem('state', serializedState);
  } catch (error) {
    // Ignore write errors.
  }
};
