
import commonValidations from './common';

const yourContactValidations = {
  'sponsor-email': {
    'isEmail': commonValidations.isEmail,
    'required': commonValidations.required,
  },
}
export default yourContactValidations;
