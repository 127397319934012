
import commonValidations from './common';

const sponsorDetailsValidations = {
  'sponsor-name': {
    'required': commonValidations.required,
  },
  'sponsor-reference': {
    'required': commonValidations.required,
  }
}
export default sponsorDetailsValidations;
