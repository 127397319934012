
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MarkdownService, { MarkdownComponents } from '../../services/markdown-sevice/';
import ReactMarkdown from 'react-markdown';
import { Button, GridCol, GridRow, H2, Link, Page } from 'govuk-react';
import { clearDidJustSet, setCookieConsent } from './uiSlice';

const CookieBanner = ({currentPage, cookieConsent}) => {

  const { t } = useTranslation();
  const components: MarkdownComponents = MarkdownService.components();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();

    const button = event.nativeEvent.submitter;

    dispatch(setCookieConsent({
      settings: {
        analytics: (button.value.toLowerCase() === 'accept'
          ? true
          : false
        )
      }
    }));
  }

  const handleHide = (event) => {
    event.preventDefault();

    dispatch(clearDidJustSet());
  };


  if (currentPage === '/cookies') {
    return null;
  }

  if (
    cookieConsent?.settings === undefined
    || cookieConsent?.didJustSet
  ) {
    return (
      <div className="govuk-cookie-banner" role="region" aria-label="{ t(`ui.cookieBanner.heading`, {serviceTitle:t(`ui.serviceTitle`)}) }">
        <Page.WidthContainer className="govuk-cookie-banner__message width-container">
          <GridRow>
            <GridCol setWidth='two-thirds'>
              {cookieConsent?.settings === undefined &&
                <H2 size="MEDIUM">{ t(`ui.cookieBanner.heading`, {serviceTitle:t(`ui.serviceTitle`)}) }</H2>
              }

              <ReactMarkdown components={components}>{
                t(
                  `ui.cookieBanner.${cookieConsent?.didJustSet ? 'didJustSet' : 'beforeSet'}`,
                  {setTo: cookieConsent?.settings?.analytics ? 'accepted' : 'rejected'}
                )
              }</ReactMarkdown>
            </GridCol>
          </GridRow>

          {cookieConsent?.settings === undefined &&
            <form onSubmit={handleSubmit}>
              <div className="govuk-button-group">
                <Button type="submit" className="govuk-button" name="cookies_analytics" value="accept">{ t(`ui.cookieBanner.accept`) }</Button>
                <Button type="submit" className="govuk-button" name="cookies_analytics" value="reject">{ t(`ui.cookieBanner.reject`) }</Button>
                <Link className="govuk-link" href="/cookies">{ t(`ui.cookieBanner.cookies-page-link`) }</Link>
              </div>
            </form>
          }

          {cookieConsent?.didJustSet &&
            <div className="govuk-button-group">
              <Button type="button" className="govuk-button" onClick={handleHide}>{ t(`ui.cookieBanner.hide`) }</Button>
            </div>
          }
        </Page.WidthContainer>
      </div>
    );
  }
  else {
    return null;
  }
}

const mapState = (state, props) => {
  return {
    cookieConsent: state.ui.cookieConsent,
  };
};

export default connect(mapState)(CookieBanner);
