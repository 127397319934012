import { createSlice } from '@reduxjs/toolkit';

export const initialStatusState = {
  loading: [],
  error: false,
  sponsorDetails: {
    name: '',
    reference: '',
  },
  sponsorLicenceNumber: {
    value: '',
    validationError: undefined
  },
  applicants: []
}

export const statusSlice = createSlice({
  name: 'status',
  initialState: initialStatusState,
  reducers: {
    setSponsorLicenceNumber: (state, action) => {
      state.sponsorLicenceNumber.value = action.payload.sponsorLicenceNumber;
    },
    setSponsorLicenceNumberValidationError: (state, action) => {
      state.sponsorLicenceNumber.validationError = action.payload.validationError;
    },
    setDetails: (state, action) => {
      state.sponsorDetails = {
        name: action.payload.sponsorName,
        reference: action.payload.sponsorReference
      }
    },
    setApplicants: (state, action) => {
      state.applicants = [
        ...action.payload.applicants
      ]
    },
    clearApplicants: (state, action) => {
      state.applicants = [
        ...initialStatusState.applicants
      ]
    },
    addLoading: (state, action) => {
      state.loading = [
        ...state.loading,
        ...action.payload
      ]
    },
    clearLoading: (state, action) => {
      state.loading = [
        ...initialStatusState.loading,
      ]
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const { 
  setSponsorLicenceNumber,
  setSponsorLicenceNumberValidationError,
  setStatuses, 
  setApplicants,
  setDetails,
  clearApplicants,
  addLoading,
  clearLoading,
  setError,
} = statusSlice.actions;
export default statusSlice.reducer; 
