
import * as React from 'react';
import { GridRow, GridCol, BackLink } from 'govuk-react';
import { useTranslation } from 'react-i18next';
import LanguagePicker from './LanguagePicker';
import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';

const SubHeader = ({doGoBack}) => {
  const { t } = useTranslation();

  const handleBackClick = (event) => {
    event.preventDefault();
    doGoBack();
  };

  return (
  <GridRow>
    <GridCol>
      <BackLink href="#back" title="Go back" onClick={handleBackClick}>
        { t('ui.actions.back')}
      </BackLink>
    </GridCol>
    {false && <GridCol style={{flexGrow: 0}}>
      <LanguagePicker />
    </GridCol>}
  </GridRow>
  )
}
const mapDispatch = (dispatch) => {
  return {
    doGoBack: (location) => {
      dispatch(goBack())
    },
  };
};

export default connect(null, mapDispatch)(SubHeader);
