
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { tier5api } from '../../app/api';
import { utils } from '../../app/utils';

class SubmissionService {

  constructor() {
    this.generateGroupId = this.generateGroupId.bind(this);
    this.transform = this.transform.bind(this);
    this.submit = this.submit.bind(this);

    this.dateFormat = 'YYYY-MM-DD'
  }

  generateGroupId(): string {
    return `CWC-${uuidv4().toUpperCase()}`
  }

  async submit(applications): Promise<any> {
    let groupId = this.generateGroupId();
    let payload = applications.map((application) => {
      return this.transform(application, groupId);
    });
    let response =  await tier5api.submit(payload);
    return response
  }
  
  transform(answers, groupId): Object {
    let lteDate = utils.calculateLTE(answers.workDates['first-work-date'], answers.workDates['last-work-date'], answers.travel['date-of-arrival']);
    let application = {
      concessionForm: {
        passportNumber: answers.passport['passport-number'],
        passportIssueDate: dayjs(answers.passport['passport-issue-date']).format(this.dateFormat),
        passportExpiryDate:  dayjs(answers.passport['passport-expiry-date']).format(this.dateFormat),
        dateOfArrivalInUK:  dayjs(answers.travel['date-of-arrival']).format(this.dateFormat),
        methodOfArrivalIntoUK: answers.travel['method-of-arrival'],
        carrier: answers.travel['carrier'],
        flightNumber: answers.travel['flight-number'],
        nameOfUKPortOfArrival: answers.travel['port-of-arrival'],
        certificateOfSponsorshipReferenceNumber: answers.cosReference['cos-reference'],
        howLongDoYouPlanToStay: `No later than ${utils.formattedDateString(lteDate)}`,
        purposeOfStay: "Working in the creative industries",
        declaration: "OnBehalfOfOrganisation",
        formGroupId: groupId,
        sponsorName: answers.sponsorDetails['sponsor-name'],
        sponsorshipReferenceNumber: answers.sponsorDetails['sponsor-reference'],
        sponsorEmailAddress: answers.sponsorContact['sponsor-email'],
        createdDate: dayjs().toISOString()
      },
      secondaryDetails: {
        familyName: answers.passengerName['family-name'],
        givenNames: answers.passengerName['given-name'],
        dateOfBirth: dayjs(answers.passengerDob['date-of-birth']).format(this.dateFormat),
        nationality: answers.nationality['nationality'],
        countryOfDeparture: "Ireland",
        airPortOfDeparture: answers.travel['port-of-departure'],
        firstWorkDate: dayjs(answers.workDates['first-work-date']).format(this.dateFormat),
        lastWorkDate: dayjs(answers.workDates['last-work-date']).format(this.dateFormat),
        lteToDate: lteDate.format(this.dateFormat),
        createdDate: dayjs().toISOString()
      }
    };
    return application;
  }
}
const ss = new SubmissionService()
export default ss;
