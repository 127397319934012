
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {utils} from '../../app/utils';
import store from '../../app/store';
import { 
  updateCurrent, 
  setErrors, 
  clearAllErrors,
  setPathCompletion, 
  setIsChanging, 
  addApplicantToGroup,
  updateGroupApplicantFromCurrent,
  addReceipts,
  clearGroup,
  clearReceipts,
  clearCurrent, 
  clearCurrentDetails, 
  setReuseFlags
} from './formSlice';
import ValidationService from '../../services/validation-service';
import SubmissionService from '../../services/submission-service';

// STORE GETTERS =======================================================
// Using redux-saga select()
// =====================================================================*/

const getCurrentForm = state => state.form.current
const getCurrentGroup = state => state.form.group

// SKIP FORM STEP ====================================================
// Used to skip a form step and mark it as complete
// =====================================================================*/

export type SkipFormStepActionType = {|
  type: "skipFormStep",
  path: string,
  nextStep: string,
|};

export const skipFormStep = (path :string, nextStep :string) :SkipFormStepActionType => {
  return {
    "type": "skipFormStep",
    "path": path,
    "nextStep": nextStep,
  };
};

function* handleSkipFormStep(action :SkipFormStepActionType) {
  try {
    yield put(setPathCompletion({
      path: action.path,
      isComplete: true,
    }));
    yield put(push(action.nextStep));
    
  } catch (e) { 
    throw e;
  }
}

export function* skipFormStepSaga() {
  yield takeEvery('skipFormStep', handleSkipFormStep);
}

// SUBMIT FORM STEP ====================================================
// Used to submit and validate a form step
// =====================================================================*/

export type SubmitFormStepActionType = {|
  type: "submitFormStep",
  fields: {},
  nextStep: string,
  namespace: string,
  path: string,
  fieldConfig: object[]
|};

export const submitFormStep = (fields :object, namespace :string, nextStep :string, path: string, fieldConfig: object[]) :SubmitFormStepActionType => {
  return {
    "type": "submitFormStep",
    "fields" : fields,
    "namespace": namespace,
    "nextStep": nextStep,
    "path": path,
    "fieldConfig": fieldConfig
  };
};

function* handleSubmitFormStep(action :SubmitFormStepActionType) {
  try {
    const currentForm = yield select(getCurrentForm)
    yield put(clearAllErrors());

    //validate for errors
    let errors = yield call(ValidationService.validate, [action.namespace, action.fields, currentForm]);

    // clear conditional answers that are no longer required
    action.fields = yield call(ValidationService.clearUnusedConditionals.bind(ValidationService), [action.fields, action.namespace, action.fieldConfig]);
    
    if (Object.keys(errors).length) {
      // If errors exist, add them to store and set this path as incomplete
      yield put(setErrors({
        errors: errors,
        namespace: action.namespace
      }));
      Object.entries(errors).forEach(([fieldName, errorName]) => store.dispatch({
        type: 'LOG_ERROR',
        path: action.path,
        fieldName,
        errorName
      }))
      yield put(setPathCompletion({
        path: action.path,
        isComplete: false,
      }));
    } else {
      // If there are no errors, clear stored errors, add the form values to the store
      yield put(setErrors({
        errors: {},
        namespace: action.namespace
      }));
      yield put(setPathCompletion({
        path: action.path,
        isComplete: true,
      }));
      yield put(updateCurrent(action));
      // Redirect to check your answers if change and answer, otherwise go to the next step
      if (currentForm.isChanging) {
        yield put(setIsChanging(false))
        yield put(push('/check-your-answers'));
      } else {
        yield put(push(action.nextStep));
      }
    }
  } catch (e) {
    throw e;
  }
}

export function* submitFormStepSaga() {
  yield takeEvery('submitFormStep', handleSubmitFormStep);
}

// SUBMIT APPLICATION ==================================================/*
// Submit single and group applications
// =====================================================================*/

export type SubmitApplictionGroupActionType = {|
  type: "submitApplictionGroup",
|};

export const submitApplictionGroup = (fields :object, namespace :string, nextStep :string, path: string) :SubmitApplictionGroupActionType => {
  return {
    "type": "submitApplictionGroup",
  };
};

const getGroup = state => state.form.group;

function* handleSubmitApplictionGroup(action :SubmitApplictionGroupActionType) {
  try {
    yield put(clearReceipts())

    const currentForm = yield select(getCurrentForm)
    if (currentForm.whatDo['what-would-you-like-to-do'] === 'single-application') {
      yield put(addApplicantToGroup());
    }

    const group = yield select(getGroup);
    let response = yield call(SubmissionService.submit, group);

    let receipts = response.data.map((application) => {return ({
      applicationReference: application.applicantId,
      groupRef: application.concessionForm.formGroupId,
      workerName: `${application.secondaryDetails.familyName}, ${application.secondaryDetails.givenNames}`,
      sponsorEmail: application.concessionForm.sponsorEmailAddress,
      cosReference: application.concessionForm.certificateOfSponsorshipReferenceNumber,
    })});
    yield put(addReceipts(receipts))
    yield put(push('/complete'));
    yield put(clearGroup());
    yield put(clearCurrent());
   
  } catch (e) {
    throw e;
  }
}

export function* submitApplictionGroupSaga() {
  yield takeEvery('submitApplictionGroup', handleSubmitApplictionGroup);
}

// ADD APPLICANT AND RETURN TO GROUP ==================================/*
// Used to return to the group edit screen after checking details 
// in a group application flow 
// =====================================================================*/

export type AddApplicantAndReturnToGroupActionType = {|
  type: "AddApplicantAndReturnToGroup",
|};

export const addApplicantAndReturnToGroup = () :AddApplicantAndReturnToGroupActionType => {
  return {
    "type": "AddApplicantAndReturnToGroup",
  };
};

function* handleAddApplicantAndReturnToGroup(action :AddApplicantAndReturnToGroupActionType) {
  try {
    const currentForm = yield select(getCurrentForm)
    
    if (currentForm.changeIndex !== '') {
      yield(put(updateGroupApplicantFromCurrent(currentForm.changeIndex)))
    } else {
      yield put(addApplicantToGroup());
    }
    
    yield put(push('/group-edit'));
    yield put(clearCurrent());
    yield put(updateCurrent({
      fields: {
        sponsorContact: {
          'sponsor-email': currentForm.sponsorContact['sponsor-email'],
        },
        sponsorDetails: {
          'sponsor-name': currentForm.sponsorDetails['sponsor-name'],
          'sponsor-reference': currentForm.sponsorDetails['sponsor-reference'],
        },
        whatDo: {
          'what-would-you-like-to-do': 'group-application'
        }
      }
    }));
    yield put(setPathCompletion({
      path: '/your-contact-details',
      isComplete: true,
    }));
    yield put(setPathCompletion({
      path: '/sponsor-details',
      isComplete: true,
    }));
    yield put(setPathCompletion({
      path: '/what-would-you-like-to-do',
      isComplete: true,
    }));
  } catch (e) {
    throw e;
  }
}

export function* addApplicantAndReturnToGroupSaga() {
  yield takeEvery('AddApplicantAndReturnToGroup', handleAddApplicantAndReturnToGroup);
}

// REUSE EXISTING DETAILS ==============================================/*
// Used to copy existing group data in the current form and start the
// add applicant process
// =====================================================================*/

export type ReuseExistingDataActionType = {|
  type: "ReuseExistingData",
  travel: string,
  workDates: string,
  next: string,
|};

export const reuseExistingData = (reuseIndexes: object, next: string) :ReuseExistingDataActionType => {
  return {
    "type": "ReuseExistingData",
    travel: reuseIndexes['reuse-travel'],
    workDates: reuseIndexes['reuse-work-dates'],
    next
  };
};

function* handleReuseExistingData(action :ReuseExistingDataActionType) {
  try {
    yield put(clearAllErrors());
    const currentGroup = yield select(getCurrentGroup)
    const payload = {};

    if(!!action.travel || action.travel === '') {
      const travelOptions = utils.dedupeObjectArray(currentGroup.map((applicant) => {
        return { ...applicant.travel }
      }));

      if (action.travel !== '') {
        payload.travel = travelOptions[parseInt(action.travel)];
      }

      yield put(setReuseFlags({
        travel: action.travel !== ''
      }));
    }

    if(action.workDates || action.workDates === '') {
      const workDateOptions = utils.dedupeObjectArray(currentGroup.map((applicant) => {
        return { ...applicant.workDates }
      }));

      if (action.workDates !== '') {
        payload.workDates = workDateOptions[parseInt(action.workDates)];
      }

      yield put(setReuseFlags({
        workDates: action.workDates !== ''
      }));
    }
    yield put(updateCurrent({fields: payload}));
    yield put(push(action.next));
  } catch (e) {
    throw e;
  }
}

export function* reuseExistingDataSaga() {
  yield takeEvery('ReuseExistingData', handleReuseExistingData);
}

// EDIT APPLICATION ==============================================/*
// Used to copy a specific application from the group into current
// and then redirect to the Check Your Answers view
// =====================================================================*/

export type EditApplicationActionType = {|
  type: "EditApplication",
  index: string,
|};

export const editApplication = (index: string) :EditApplicationActionType => {
  return {
    "type": "EditApplication",
    index: index,
  };
};

function* handleEditApplication(action :EditApplicationActionType) {
  try {
    yield put(clearAllErrors());
    yield put(clearCurrentDetails());
    const currentGroup = yield select(getCurrentGroup)
    const targetApplication = currentGroup[parseInt(action.index)]
    yield put(updateCurrent({fields: {
      ...targetApplication,
      changeIndex: action.index,
      isChanging: true,
    }}));
    yield put(push('/check-your-answers'));
  } catch (e) {
    throw e;
  }
}

export function* editApplicationSaga() {
  yield takeEvery('EditApplication', handleEditApplication);
}

// ADD NEW APPLICATION ==============================================/*
// Used to initiate the add applicant flow
// =====================================================================*/

export type AddApplicationActionType = {|
  type: "AddApplication",
  offerReuse: boolean
|};

export const addApplication = (offerReuse: boolean) :AddApplicationActionType => {
  return {
    "type": "AddApplication",
    offerReuse
  };
};

function* handleAddApplication(action :AddApplicationActionType) {
  try {
    const currentForm = yield select(getCurrentForm);

    if (currentForm.isChanging) {
      yield put(clearCurrentDetails());
      yield put(updateCurrent({fields: {
        changeIndex: '',
        isChanging: false,
      }}));
    }
    yield put(push('/passenger-name'));

  } catch (e) {
    throw e;
  }
}

export function* addApplicationSaga() {
  yield takeEvery('AddApplication', handleAddApplication);
}

// FINISH ==============================================/*
// Used to reset the form
// =====================================================================*/

export type FinishApplicationActionType = {|
  type: "FinishApplication"
|};

export const finishApplication = (offerReuse: boolean) :FinishApplicationActionType => {
  return {
    "type": "FinishApplication",
    offerReuse
  };
};

function* handleFinishApplication(action :FinishApplicationActionType) {
  try {
    yield put(clearCurrentDetails());
    yield put(clearGroup());
    yield put(clearReceipts());
    yield put(push('/'));

  } catch (e) {
    throw e;
  }
}

export function* finishApplicationSaga() {
  yield takeEvery('FinishApplication', handleFinishApplication);
}
