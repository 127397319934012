// @flow

import nationalityValidations from '../../features/fields/validations/nationality-validations.js';
import placeOfDepartureValidations from '../../features/fields/validations/place-of-departure-validations.js';
import passportValidations from '../../features/fields/validations/passport-validations.js';
import travelValidations from '../../features/fields/validations/travel-validations.js';
import workDateValidations from '../../features/fields/validations/work-date-validations.js';
import whatDoValidations from '../../features/fields/validations/what-would-you-like-to-do.js';
import passengerNameValidations from '../../features/fields/validations/passenger-name-validations.js';
import passengerDobValidations from '../../features/fields/validations/passenger-dob-validations.js';
import yourContactValidations from '../../features/fields/validations/your-contact-validations.js';
import cosReferenceValidations from '../../features/fields/validations/cos-reference-validations.js';
import sponsorDetailsValidations from '../../features/fields/validations/sponsor-details-validations.js';
import statusValidations from '../../features/fields/validations/status-validations.js';
import type { ObjectPattern } from "ast-types-flow";


interface IValidationService {
  satisfiesConditions(values:Object, conditions: [string, string][]): boolean,
  clearUnusedConditionals(props: Object[]): Object,
  validate(props: Object): Object,
}

class ValidationService {

  //user to check whether a the conditions of a field have been met
  satisfiesConditions(values, conditions): boolean {
    if (!conditions) return true;
    let checks = conditions.map(condition => {
      return values[condition[0]] === condition[1]
    })
    let someFailing = checks.some((check) => check === false)
    return !someFailing;
  }

  //clears values that don't meet field conditions (eg. no longer required)
  clearUnusedConditionals(props): ObjectPattern {
    const fields = props[0];
    const namespace = props[1];
    const fieldConfig = props[2];
    
    fieldConfig.forEach((config) => {
      if (!this.satisfiesConditions(fields[namespace], config.conditions)) {
        delete fields[namespace][config['name']];
      }
    })
    return fields
  }

  validate = (props): Object => {
    const namespace = props[0];
    const fields = props[1];
    const allFields = props[2];

    const validators = {
      nationality: nationalityValidations,
      departure: placeOfDepartureValidations,
      passengerName: passengerNameValidations,
      passengerDob: passengerDobValidations,
      passport: passportValidations,
      travel: travelValidations,
      workDates: workDateValidations,
      whatDo: whatDoValidations,
      sponsorContact: yourContactValidations,
      sponsorDetails: sponsorDetailsValidations,
      cosReference: cosReferenceValidations,
      status: statusValidations,
    };

    const fieldValidations = validators[namespace];
    
    const stepErrors = {};
    Object.keys(fieldValidations).forEach((fieldName) => {
      const validations = fieldValidations[fieldName];
      Object.keys(validations).forEach((validationKey) => {
        const validator = validations[validationKey];
        const value = fields[namespace] && fields[namespace][fieldName]
        const passing = validator(value, fields, allFields)
        if (!passing) {
          stepErrors[fieldName] = validationKey;
        }
      })
    })

    return stepErrors;
  }
}
const vs: IValidationService = new ValidationService()
export default vs;
