import validator from 'validator';
import commonValidations from './common';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {utils} from '../../../app/utils';
dayjs.extend(isSameOrBefore);

const travelValidations = {
  'port-of-departure': {
    'required': commonValidations.required,
  },
  'date-of-arrival': {
    'isAfter': (value) => {
      return value && validator.isAfter(value, new Date().toString())
    },
    'isDate': commonValidations.isDate,
    'required': commonValidations.required,
    'incompatibleWithWorkDates':(value, fields, allFields) => {
      let fwd = allFields.workDates['first-work-date']
      let lwd = allFields.workDates['last-work-date']
      if (value && fwd && fwd !== '' && lwd && lwd !== '') {
        return utils.isWorkDateCompatibleWithArrivalDate(new Date(value), new Date(fwd), new Date(lwd));
      }
      return true;
    }
  },
  'method-of-arrival': {
    'isValidOption': (value) => {
      return value && validator.isIn(value, [
        'plane',
        'road',
      ])
    },
    'required': commonValidations.required,
  },
  'port-of-arrival': {
    'required': commonValidations.required,
  },
  'carrier': {
    'required': (value, fields) => {
      let moa = fields.travel['method-of-arrival']
      if (moa === 'plane') {
        return commonValidations.required(value)
      } else {
        return true
      }
    }
  },
  'flight-number': {
    'required': (value, fields) => {
      let moa = fields.travel['method-of-arrival']
      if (moa === 'plane') {
        return commonValidations.required(value)
      } else {
        return true
      }
    }
  },
}
export default travelValidations;
