import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { googleAnalytics4Middleware } from '../services/google-analytics';
import { rootSaga } from './saga.js';
import { loadState, saveState } from './sessionStorage';

import uiReducer from '../features/ui/uiSlice';
import formReducer from '../features/form/formSlice';
import statusReducer from '../features/status/statusSlice.js';

export const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  ui: uiReducer,
  form: formReducer,
  status: statusReducer,
});

export const history = createBrowserHistory({
  basename: ''
});
export const sagaMiddleware = createSagaMiddleware();

function configureStore(preloadedState) {

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const persistedState = loadState();

  const store = createStore(
    createRootReducer(history),
    persistedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
        googleAnalytics4Middleware
      )
    )
  );

  store.subscribe(() => {
    saveState(store.getState());
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
const store = configureStore({})
export default store;
