import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  group: [],
  receipts: [],
  errors: {
    sponsorContact: {},
    sponsorDetails: {},
    nationality: {},
    whatDo: {},
    passengerDob: {},
    passengerName: {},
    passport:{},
    travel: {},
    workDates: {},
    workerContact: {},
    cosReference:{}
  },
  current: {
    changeIndex: '',
    isChanging: false,
    complete: [],
    reuse: {
      travel: false,
      workDates: false,
    },
    sponsorContact: {
      'sponsor-email': '',
    },
    sponsorDetails: {
      'sponsor-name': '',
      'sponsor-reference': ''
    },
    whatDo: {
      'what-would-you-like-to-do': '',
    },
    nationality: {
      'nationality': '',
    },
    passengerName: {
      'family-name': '',
      'given-name': '',
    },
    passengerDob: {
      'date-of-birth': '',
    },
    passport: {
      'passport-number': '',
      'passport-expiry-date':  '',
      'passport-issue-date':  '',
    },
    travel: {
      'port-of-departure':  '',
      'date-of-arrival':  '',
      'method-of-arrival':  '',
      'port-of-arrival':  '',
      'carrier':  '',
      'flight-number':  '',
    },
    workDates: {
      'first-work-date':  '',
      'last-work-date':  '',
    },
    cosReference: {
      'cos-reference': '',
    },
  }
}

export const formSlice = createSlice({
  name: 'form',
  initialState: initialState,
  reducers: {
    addApplicantToGroup: (state, action) => {
      state.group = [
        ...state.group,
        state.current
      ]
    },
    updateGroupApplicantFromCurrent: (state, action) => {
      state.group = state.group.map((applicant, index) => {
        if (index === action.payload) {
          return {
            ...state.current,
            changeIndex: ''
          }
        } else {
          return applicant
        }
      })
    },
    deleteApplicantFromGroup: (state, action) => {
      state.group = [
        ...state.group.slice(0, action.payload),
        ...state.group.slice(action.payload + 1)
      ]
    },
    addReceipts: (state, action) => {
      state.receipts = [
        ...action.payload
      ];
    },
    clearReceipts: (state, action) => {
      state.receipts = [
        ...initialState.receipts
      ];
    },
    clearGroup: (state, action) => {
      state.group = [
        ...initialState.group
      ];
    },
    clearCurrent: (state, action) => {
      state.current = {
        ...initialState.current
      };
    },
    clearCurrentDetails: (state, action) => {
      let resetState = {
        nationality: {
          ...initialState.current.nationality
        },
        passengerName: {
          ...initialState.current.passengerName
        },
        passengerDob: {
          ...initialState.current.passengerDob
        },
        passport: {
          ...initialState.current.passport
        },
        travel: {
          ...initialState.current.travel
        },
        workDates: {
          ...initialState.current.workDates
        },
        cosReference: {
          ...initialState.current.cosReference
        }
      }
      state.current = { 
        ...state.current,
        ...resetState 
      };
    },
    updateCurrent: (state, action) => {
      state.current = {
        ...state.current,
        ...action.payload.fields
      };
    },
    setReuseFlags: (state, action) => {
      state.current.reuse = {
        ...state.current.reuse,
        ...action.payload
      };
    },
    setErrors: (state, action) => {
      state.errors[action.payload.namespace] = {
        ...action.payload.errors
      };
    },
    clearAllErrors: (state, action) => {
      state.errors = {
        ...initialState.errors
      };
    },
    setIsChanging: (state, action) => {
      state.current.isChanging = action.payload;
    },
    setPathCompletion: (state, action) => {
      if (action.payload.isComplete) {
        state.current.complete = [
          ...new Set([
            ...state.current.complete,
            action.payload.path
          ])
        ]
      } else {
        state.current.complete = [
          ...state.current.complete.filter(completed => completed !== action.payload.path)
        ]
      }
    },
  }
});

export const { 
  updateCurrent, 
  setReuseFlags,
  setErrors, 
  clearAllErrors,
  setPathCompletion, 
  setIsChanging, 
  addApplicantToGroup,
  addReceipts,
  clearReceipts,
  clearGroup,
  clearCurrent,
  clearCurrentDetails,
  updateGroupApplicantFromCurrent,
  deleteApplicantFromGroup
} = formSlice.actions;
export default formSlice.reducer; 
