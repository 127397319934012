// @flow

import { all } from 'redux-saga/effects'

//Feature Sagas
import { 
  skipFormStepSaga,
  submitFormStepSaga, 
  submitApplictionGroupSaga, 
  addApplicantAndReturnToGroupSaga,
  reuseExistingDataSaga,
  editApplicationSaga,
  addApplicationSaga,
  finishApplicationSaga
} from '../features/form/formSaga';

import { 
  validateSponsorLicenceNumberSaga,
  getApplicantStatusesSaga,
  downloadPDFsSaga, 
} from '../features/status/statusSaga';

import {
   cookieConsentSaga
 } from '../features/ui/uiSaga';

export function* rootSaga() :any {
  yield all([
    skipFormStepSaga(),
    submitFormStepSaga(),
    submitApplictionGroupSaga(),
    addApplicantAndReturnToGroupSaga(),
    reuseExistingDataSaga(),
    editApplicationSaga(),
    addApplicationSaga(),
    finishApplicationSaga(),
    validateSponsorLicenceNumberSaga(),
    getApplicantStatusesSaga(),
    downloadPDFsSaga(),
    cookieConsentSaga(),
  ]);
}

