import { call, put, select, takeEvery } from 'redux-saga/effects';
import { setSponsorLicenceNumberValidationError, setApplicants, setDetails, addLoading, clearLoading, setError } from './statusSlice';
import { tier5api } from '../../app/api';
import ValidationService from '../../services/validation-service';


// VALIDATE SPONSOR LICENCE NUMBER ====================================================
// Used to validate the sponsor licence number
// =====================================================================*/

function* validateSponsorLicenceNumber(action) {

  const fields = {
    'status': {
      'sponsor-licence-number': yield(select(state => state.status.sponsorLicenceNumber.value))
    }
  };

  const validationErrors = yield call(ValidationService.validate, ['status', fields, fields]);

  if (Object.keys(validationErrors).includes('sponsor-licence-number')) {
    yield put(setSponsorLicenceNumberValidationError({
      validationError: validationErrors['sponsor-licence-number']
    }));
  }
  else {
    yield put(setSponsorLicenceNumberValidationError({
      validationError: undefined
    }));
  }
}

function* handleSponsorLicenceNumberValidation(action) {
  if (action.payload.validationError === undefined) {
    const token = new window.URLSearchParams(window.location.search).get("epat");
    const sponsorLicenceNumber = yield(select(state => state.status.sponsorLicenceNumber.value));

    yield put(getApplicantStatuses(token, sponsorLicenceNumber));
  }
}

export function* validateSponsorLicenceNumberSaga() {
  yield takeEvery('status/setSponsorLicenceNumber', validateSponsorLicenceNumber);
  yield takeEvery('status/setSponsorLicenceNumberValidationError', handleSponsorLicenceNumberValidation);
}


// GET APPLICANT STATUS ====================================================
// Used to get statuses of all applicants in a group
// =====================================================================*/

export type GetApplicantStatusesActionType = {|
  type: "getApplicantStatuses",
  epat: string,
  sponsorLicenceNumber: string,
|};

export const getApplicantStatuses = (epat :string, sponsorLicenceNumber :string) :SkipFormStepActionType => {
  return {
    "type": "getApplicantStatuses",
    "epat": epat,
    "sponsorLicenceNumber": sponsorLicenceNumber
  };
};

function* handleGetApplicantStatuses(action :SkipFormStepActionType) {
  let payload = {
    publicAccessTokenJWT: action.epat,
    sponsorLicenceNumber: action.sponsorLicenceNumber
  };
  try {
    const statuses = yield call(tier5api.getApplicationStatuses, payload)

    if (statuses.data.items) {
      yield put(setDetails({
        sponsorName: statuses.data.sponsorName,
        sponsorReference: statuses.data.sponsorReference
      }));
      yield put(setApplicants({
        applicants: statuses.data.items,
      }));
    } else {
      yield put(setError(true));
    }
    
  } catch (e) { 
    if (e.response.status === 403 && e.response.data.errorType === 'AuthorisationError') {
      yield put(setSponsorLicenceNumberValidationError({ validationError : 'doesNotMatch' }));
    } else if (e.response.status === 429 && e.response.data.errorType === 'RateLimitError') {
      yield put(setSponsorLicenceNumberValidationError({ validationError : 'tooManyRequests' }));
    } else {
      yield put(setError(e));
    }
  }
}

export function* getApplicantStatusesSaga() {
  yield takeEvery('getApplicantStatuses', handleGetApplicantStatuses);
}


// DOWNLOAD APPLICANTS DOCS ====================================================
// Used to get all applicants in a group
// =====================================================================*/

export type DownloadPDFsActionType = {|
  type: "downloadPDFs",
  epat: string,
  sponsorLicenceNumber: string,
  applicantIds: string[],
  filename: string,
  isGroup: boolean,
|};

export const downloadPDFs = (epat :string, sponsorLicenceNumber :string, applicantIds: string[], filename: string, isGroup: boolean) :SkipFormStepAcDownloadPDFsActionTypetionType => {
  return {
    "type": "downloadPDFs",
    "epat": epat,
    "sponsorLicenceNumber": sponsorLicenceNumber,
    "applicantIds": applicantIds,
    "filename": filename,
    "isGroup": isGroup
  };
};

function* handleDownloadPDFs(action :SkipFormStepActionType) {
  let payload = {
    publicAccessTokenJWT: action.epat,
    sponsorLicenceNumber: action.sponsorLicenceNumber,
    applicantIds: action.applicantIds,
  };
  try {  
    yield put(clearLoading());
    yield put(addLoading(action.isGroup ? ['group'] : action.applicantIds));

    const response = yield call(tier5api.downloadApplicantDocs, payload)

    if (response.data) {
      const blob = new Blob([response.data], {type: response.headers['content-type']});
      const anchor = document.createElement('a');
  
      if (window.navigator.msSaveOrOpenBlob !== undefined) {
        window.navigator.msSaveOrOpenBlob(blob, "pdf.pdf");
      } else {
        const url = window.URL.createObjectURL(blob);
        anchor.href = url;
        anchor.download = `${action.filename}.pdf`;
        anchor.click();
        window.URL.revokeObjectURL(blob);
        yield put(clearLoading());
      }
    } else {
      setError(true)
    }
  } catch (e) { 
    yield put(setError(true));
    throw e;
  }
}

export function* downloadPDFsSaga() {
  yield takeEvery('downloadPDFs', handleDownloadPDFs);
}
