import validator from 'validator';
import commonValidations from './common';

const passengerNameValidations = {
  'date-of-birth': {
    'isBefore': (value) => {
      return value && validator.isBefore(value, new Date().toString())
    },
    'isDate': commonValidations.isDate,
    'required': commonValidations.required,
  }
}
export default passengerNameValidations;
