import commonValidations from './common';

const passengerNameValidations = {
  'family-name': {
    'required': commonValidations.required,
  },
  'given-name': {
    'required': commonValidations.required,
  }
}
export default passengerNameValidations;
