//@flow

import axios from "axios";

export const tier5api = {
  submit: async (payload: Object[]) :Promise<{}> => {
    return await httpCall('POST', '/api/public/newFromConcession', payload, 'json');
  },
  getApplicationStatuses: async (payload: Object[]) :Promise<{}> => {
    return await httpCall('POST', '/api/public/publicStatus', payload, 'json');
  },
  downloadApplicantDocs: async (payload: Object[]) :Promise<{}> => {
    return await httpCall('POST', '/api/public/pdf-maker', payload, 'blob');
  },
};

async function httpCall(method :"GET" | "POST" | "DELETE", url :string, payload :mixed, responseType :string) :Promise<{}> {
  return await axios({
    'url': url,
    'method': method,
    'data': payload ? payload : {},
    'withCredentials': true,
    'headers': {
      'Content-Type': 'application/json', 
    },
    'responseType': responseType,
  });
}

