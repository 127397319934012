import { put, takeEvery } from 'redux-saga/effects';
import { clearDidJustSet, setCookieConsent } from './uiSlice';


const consentCookie = {
  name: 'cookieConsent_analytics',
  maxAge: 60*60*24*365 // 1 year
};


export function* cookieConsentSaga() {
  yield takeEvery('ui/initCookieConsent', handleInitCookieConsent);
  yield takeEvery('ui/setCookieConsent', handleSetCookieConsent);
  yield takeEvery('@@router/LOCATION_CHANGE', handleRouteChange);
}

function* handleInitCookieConsent(action) {
  const existingConsentCookie = window?.document?.cookie?.split(';')
    .find(cookieNameValue =>
      cookieNameValue.trim().split('=')[0] === consentCookie.name
    );

  if (existingConsentCookie) {
    yield put(setCookieConsent({
      settings: {
        analytics: existingConsentCookie.endsWith('=true')
      },
      isFromCookie: true
    }));
  }
}

function handleSetCookieConsent(action) {
  if (!action.payload.isFromCookie
    && action.payload.settings
    && window?.document
  ) {
    window.document.cookie = `${consentCookie.name}=${action.payload.settings.analytics ? 'true' : 'false'};max-age=${consentCookie.maxAge}`
  }
}

function* handleRouteChange(action) {
  yield put(clearDidJustSet());
}
