// @flow

import React from 'react';
import {H1, H2, H3, H4, H5, H6, UnorderedList, OrderedList, ListItem, InsetText, Link as GovLink } from 'govuk-react';

export interface MarkdownComponentProps {
  children?: string;
  href?: string;
}

export interface MarkdownComponents {
  [key: string]: Function;
}

interface IMarkdownService {
  components(): MarkdownComponents
}

class MarkdownService {
  components() {
    const components:MarkdownComponents = {
      p: (props: MarkdownComponentProps) => {
        return <p>{ props.children}</p>
      },
      h1: (props: MarkdownComponentProps) => {
        return <H1>{ props.children }</H1>
      },
      h2: (props: MarkdownComponentProps) => {
        return <H2>{ props.children }</H2>
      },
      h3: (props: MarkdownComponentProps) => {
        return <H3>{ props.children }</H3>
      },
      h4: (props: MarkdownComponentProps) => {
        return <H4>{ props.children }</H4>
      },
      h5: (props: MarkdownComponentProps) => {
        return <H5>{ props.children }</H5>
      },
      h6: (props: MarkdownComponentProps) => {
        return <H6>{ props.children }</H6>
      },
      hr: () => {
        return <hr className="govuk-section-break govuk-section-break--visible" />
      },
      ul: (props: MarkdownComponentProps) => {
        return <UnorderedList>{props.children}</UnorderedList>
      },
      ol: (props: MarkdownComponentProps) => {
        return <OrderedList>{props.children}</OrderedList>
      },
      li: (props: MarkdownComponentProps) => {
        return <ListItem>{props.children}</ListItem>
      },
      blockquote: (props: MarkdownComponentProps) => {
        return <InsetText>{props.children}</InsetText>
      },
      a: (props: MarkdownComponentProps) => {
        return <GovLink href={props.href}>{props.children}</GovLink>
      },
      script: () => {
        return <sadtrombone></sadtrombone>
      }
    }
    return components;
  }
}

const ms: IMarkdownService = new MarkdownService();
export default ms;
