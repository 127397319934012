

//Libs
import React from 'react';
import { GlobalStyle, Page, Footer } from 'govuk-react';
import {NotificationContainer} from 'react-notifications';
import Header from './features/ui/Header';
import SubHeader from './features/ui/SubHeader';
import { useTranslation, withTranslation } from 'react-i18next';
import CookieBanner from './features/ui/CookieBanner';

//Styles
import 'react-notifications/lib/notifications.css';
import './assets/scss/index.scss'

//UI Components
import Routes from './Routes';

export function App({history, i18n}: Object) :React.Node {
  const { t } = useTranslation();

  let direction = '';
  switch (i18n.language) {
    case 'ar':
      direction = 'rtl';
      break;
    default:
      direction = 'ltr';
      break;
  }

  const widthContainer = () => 
    <Page.WidthContainer className="width-container">
      <SubHeader />
      <Page.Main id={'content'}>{/* Match the default id of the Page component, which is used to render the href of the skip link: https://govuk-react.github.io/govuk-react/?path=/docs/page--docs */}
        <Routes history={history} />
      </Page.Main>
    </Page.WidthContainer>

  return (
    <div className="App">
      <bdo dir={direction}>
        <GlobalStyle />
        <CookieBanner currentPage={window.location.pathname} />
        <Page 
          header={<Header />}
          beforeChildren={<SubHeader />}
          container={widthContainer}
        />
        <Footer
          meta={<Footer.MetaLinks heading="Support links">
            <Footer.Link href="https://www.gov.uk/done/apply-for-creative-worker-concession">{t(`ui.footer.links.feedback`)}</Footer.Link>
            <Footer.Link href="/terms-and-conditions">{t(`ui.footer.links.terms`)}</Footer.Link>
            <Footer.Link href="/privacy-policy">{t(`ui.footer.links.privacy`)}</Footer.Link>
            <Footer.Link href="/cookies">{t(`ui.footer.links.cookies`)}</Footer.Link>
            <Footer.Link href="/accessibility">{t(`ui.footer.links.accessibility`)}</Footer.Link>
          </Footer.MetaLinks>}
        />
        <NotificationContainer />
        </bdo>
    </div>
  );
}

export default withTranslation()(App);
