import validator from 'validator';
import commonValidations from './common';

const passportValidations = {
  'passport-number': {
    'alphanumeric': commonValidations.alphanumeric,
    'length': (value) => {
      return value && validator.isLength(value, {
        min: 3,
        max: 15
      })
    },
    'required': commonValidations.required,
  },
  'passport-issue-date': {
    'isBefore': (value) => {
      return value && validator.isBefore(value, new Date().toString())
    },
    'isDate': commonValidations.isDate,
    'required': commonValidations.required,
  },
  'passport-expiry-date': {
    'isAfter': (value) => {
      return value && validator.isAfter(value, new Date().toString())
    },
    'isDate': commonValidations.isDate,
    'required': commonValidations.required,
  }
}
export default passportValidations;
