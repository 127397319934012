// @flow

import * as React from 'react';
import 'react-notifications/lib/notifications.css';
import './assets/scss/index.scss'
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import { LoadingBox } from 'govuk-react';


type AppPropsType = {
  history: {},
};

//Lazy loaded views (for webpack code splitting)
const Home = React.lazy(() => import('./features/static/Home'))
const Nationality = React.lazy(() => import('./features/form/Nationality'))
const YourContactDetails = React.lazy(() => import('./features/form/YourContactDetails'))
const SponsorDetails =  React.lazy(() => import('./features/form/SponsorDetails'))
const PlaceOfDeparture = React.lazy(() => import('./features/form/PlaceOfDeparture'))
const WhatWouldYouLikeToDo = React.lazy(() => import('./features/form/WhatWouldYouLikeToDo'))
const PassengerName = React.lazy(() => import('./features/form/PassengerName'))
const PassengerDob = React.lazy(() => import('./features/form/PassengerDateOfBirth'))
const CosReference = React.lazy(() => import('./features/form/CosReference'))

const PassportDetails = React.lazy(() => import('./features/form/PassportDetails'))
const TravelDetails = React.lazy(() => import('./features/form/TravelDetails'))

const WorkDates = React.lazy(() => import('./features/form/WorkDates'))
const CheckYourAnswers = React.lazy(() => import('./features/form/CheckYourAnswers'))
const Complete = React.lazy(() => import('./features/form/Complete'))
const GroupEdit = React.lazy(() => import('./features/group/GroupEdit'))
const ReuseTravelDetails = React.lazy(() => import('./features/group/ReuseTravelDetails'))
const ReuseWorkDates = React.lazy(() => import('./features/group/ReuseWorkDates'))

const Status = React.lazy(() => import('./features/status/Status'))

const TermsAndConditions = React.lazy(() => import('./features/static/TermsAndConditions'))
const PrivacyPolicy = React.lazy(() => import('./features/static/PrivacyPolicy'))

const CookiesPage = React.lazy(() => import('./features/ui/CookiesPage'))


const AccessibilityStatement = React.lazy(() => import('./features/static/AccessibilityStatement'))

export function Routes(props : AppPropsType) :React.Node {
  return (
    <ConnectedRouter history={ props.history }>
      <React.Suspense fallback={<LoadingBox id="spinner" loading={true}></LoadingBox>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/your-contact-details" component={YourContactDetails} />
          <Route exact path="/sponsor-details" component={SponsorDetails} />
          <Route exact path="/what-would-you-like-to-do" component={WhatWouldYouLikeToDo} />
          <Route exact path="/nationality" component={Nationality} />
          <Route exact path="/place-of-departure" component={PlaceOfDeparture} />
          <Route exact path="/passenger-name" component={PassengerName} />
          <Route exact path="/passenger-date-of-birth" component={PassengerDob} />
          <Route exact path="/passport-details" component={PassportDetails} />
          <Route exact path="/travel-details" component={TravelDetails} />
          <Route exact path="/cos-reference" component={CosReference} />
          <Route exact path="/work-dates" component={WorkDates} />
          <Route exact path="/check-your-answers" component={CheckYourAnswers} />
          <Route exact path="/complete" component={Complete} />
          <Route exact path="/group-edit" component={GroupEdit} />
          <Route exact path="/reuse-travel-details" component={ReuseTravelDetails} />
          <Route exact path="/reuse-work-dates" component={ReuseWorkDates} />
          <Route exact path="/status" component={Status} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/cookies" component={CookiesPage} />
          <Route exact path="/accessibility" component={AccessibilityStatement} />
          <Redirect from="*" to="/" />
        </Switch>
      </React.Suspense>
    </ConnectedRouter>
  );
}

export default Routes;
