import validator from 'validator';


const commonValidations = {
  'alphanumeric': (value) => {
    return value && validator.isAlphanumeric(value)
  },
  'required': (value) => {
    return !!value.trim()
  },
  'isDate': (value) => {
    return validator.isDate(new Date(value))
  },
  'isEmail': (value) => {
    return validator.isEmail(value)
  },
}
export default commonValidations;
