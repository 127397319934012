import validator from 'validator';
import commonValidations from './common';
import dayjs from 'dayjs';

const workDateValidations = {
  'first-work-date': {
    'isDate': commonValidations.isDate,
    'required': commonValidations.required,
    'isFuture': (value) => {
      return value && validator.isAfter(value, new dayjs().startOf('day').add(3, 'days').subtract(1, 'second').toString())
    },
    'isValidWithArrival': (value, fields, allFields) => {
      let doa = allFields.travel['date-of-arrival']
      return value && (Math.abs(dayjs(value).diff(doa, 'days')) <= 14)
    }
  },
  'last-work-date': {
    'required': commonValidations.required,
    'isDate': commonValidations.isDate,
    'isFuture': (value) => {
      return value && validator.isAfter(value, new dayjs().startOf('day').add(3, 'days').subtract(1, 'second').toString())
    },
    'isAfterOrEqualFirst': (value, fields) => {
      let fwd = dayjs(fields.workDates['first-work-date']);
      return value && (dayjs(value).diff(fwd, 'days') >= 0)
    },
    'lessThan90': (value, fields, allFields) => {
      let doa = dayjs(allFields.travel['date-of-arrival'])
      let firstDate;
      if (fields.workDates && fields.workDates['first-work-date']) {
        let fwd = dayjs(fields.workDates['first-work-date']);
        let arrivesBeforeWorkStart = doa.isBefore(fwd)
        firstDate = arrivesBeforeWorkStart ? doa : fwd
      } else {
        firstDate = doa
      }
      return value && (dayjs(value).diff(firstDate, 'month', true) <= 3)
    }
  }
}
export default workDateValidations;
