
import * as React from 'react';
import { TopNav } from 'govuk-react';
import { ReactComponent as Crown} from '../../assets/svg/Crown.svg';
import { useTranslation } from 'react-i18next';

const GovLogo= () => {
  return (
    <TopNav.Anchor href="https://www.gov.uk/" target="new">
      <TopNav.IconTitle icon={<Crown height="32" width="36"/>}>GOV.UK</TopNav.IconTitle>
    </TopNav.Anchor>
  )
}

const Header = () => {
  const { t } = useTranslation();
  return (
  <>
    <TopNav 
      company={<GovLogo />}
      serviceTitle={t( 'ui.serviceTitle')}
      >
    </TopNav>
  </>
  )
}

export default Header;
