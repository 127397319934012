// @flow
import type { LocationType } from './types/uiTypes.js';
import dayjs from 'dayjs';
import { default as utc } from 'dayjs/plugin/utc';
import { default as timezone } from 'dayjs/plugin/timezone';

// in : 2021-01-02T12:13:45Z  produces 02/01/2021 12:13pm

export const appLocale = 'en-GB';
export const appTz = 'Europe/London';

dayjs.extend(utc);
dayjs.extend(timezone);

export const utils = {

  // prefer this over direct interaction with lodash

  // functions for working with time in a generic way,
  // uses a preconfigured dayjs.

  calculateLTE :(firstWorkDate :Date, lastWorkDate:Date, arrivalDate:Date ) :Date => {

    // We assume all supplied dates are valid in relation to each other; validating that is handled by field validation

    const start = (
      dayjs(firstWorkDate).isBefore( dayjs(arrivalDate) )
        ? firstWorkDate
        : arrivalDate
    );

    const afterStart = dayjs(start).add(3, 'months');
    const afterLastWorkDate = dayjs(lastWorkDate).add(14, 'days');

    const mustLeaveBy = (
      afterLastWorkDate.isBefore(afterStart)
        ? afterLastWorkDate
        : afterStart
    );

    return mustLeaveBy;
  },

  isWorkDateCompatibleWithArrivalDate: (arrivalDate :Date, firstWorkDate :Date, lastWorkDate :Date): boolean => {
    let nonWorkAllowance = 14;
    let arrivesBeforeWorkStart = dayjs(arrivalDate).isBefore(firstWorkDate);
    let firstDate = arrivesBeforeWorkStart ? arrivalDate : firstWorkDate;

    // check the stay isn't longer than 30 days
    let lastPossibleDay = dayjs(firstDate).add(3,'months');
    if (lastPossibleDay.isBefore(lastWorkDate)) {
      return false
    }

    if (arrivesBeforeWorkStart) {
      let preWorkPeriod = dayjs(firstDate).diff(arrivalDate, 'days');
      if (preWorkPeriod > nonWorkAllowance)
        return false;
    }

    return true;
  },

  nowIsoString :() :string => {
    return dayjs().utc().format();
  },

  localiseIsoString : (isoDateString :string) :string => {
    return dayjs(isoDateString).tz(appTz).locale(appLocale).format("DD/MM/YYYY HH:mm");
  },

  formattedDateString : (date :string) :string => {
    return dayjs(date).isValid() ? dayjs(date).format("DD MMMM YYYY") : date;
  },

  formattedDateTimeString : (date :string) :string => {
    return dayjs(date).isValid() ? dayjs(date).format("DD MMMM YYYY HH:mm") : date;
  },

  // replace date applies a new "local" date to an utc string
  // so given 2021-07-01T00:22 and 01:22 london time, will set the
  // date to 2021-06-31T23:22:00Z.

  replaceDate: (isoString :string, newDate :string) :string => {
    const localDateTime = dayjs(isoString).tz(appTz).locale(appLocale);
    const [ year, month, date ] = newDate.split("-");
    const newDateV = localDateTime
        .set('date', date)
        .set('month', Number(month) -1)
        .set('year', year);
    return newDateV.utc().format();
  },

  // time is same as above.

  replaceTime: (isoString :string, newTime :string) :string => {
    const localDateTime = dayjs(isoString).tz(appTz).locale(appLocale);
    const [ hour, minute ] = newTime.split(":");
    const newDateV = localDateTime
      .set('hour', hour)
      .set('minute', minute);
    return newDateV.utc().format();
  },

  // extracts the date element of a utc iso string and localises it to europe/london timezone

  extractLocalDateFromIso: (isoDateString :string) :string => {
    return dayjs(isoDateString).tz(appTz).locale(appLocale).format("YYYY-MM-DD");
  },
  extractLocalTimeFromIso: (isoDateString :string) :string => {
    return dayjs(isoDateString).tz(appTz).locale(appLocale).format("HH:mm");
  },

  // these functions help add/remove parameters from URLs

  removeParameter: (location :LocationType, parameterName :string) :string => {
    let params = new URLSearchParams(location.search);
    params.delete(parameterName);
    return (location.pathname + "?" + params.toString());
  },

  addParameter: (location :LocationType, parameterName :string, newValue :string) :string => {
    let params = new URLSearchParams(location.search);
    params.delete(parameterName);
    params.append(parameterName, newValue);
    return (location.pathname + "?" + params.toString());
  },


  // some array manipulation functions are here.
  // Object.entities and Object.values dont work well with flow
  // so here are some things to help avoid using objects as dictionaries

  arrAppend: <T>(arr :Array<T>, newElement :T) :Array<T> => {
    return arr.concat(newElement);
  },

  arrDelete: <T>(arr :Array<T>, match :(T) => boolean) :Array<T> => {
    return arr.filter(i => !match(i));
  },

  arrUpdate: <T>(arr :Array<T>, match :(T) => boolean, transform :(T) => T) :Array<T> => {
    return arr.map(i => match(i) ? transform(i) : i);
  },

  arrUpsert: <T>(arr :Array<T>, match :(T) => boolean, transform :(T) => T, newItem :() => T) :Array<T> => {
    var didMatch = false;

    const recordMatch = (t :T) => {
      const matchResult = match(t);
      if (matchResult)
        didMatch = true;
      return matchResult;
    };
    const updatedList = utils.arrUpdate(arr, recordMatch, transform);
    return (didMatch ? updatedList : utils.arrAppend(updatedList, newItem()));
  },

  isLegacyIE: () :boolean => {
    return (window.navigator.msSaveOrOpenBlob !== undefined);
  },

  openPdf: (filename :string, data :any) :void => {
    var blob = new Blob(data, {type: 'application/pdf'});
    if (window.navigator.msSaveOrOpenBlob !== undefined) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const url = window.URL.createObjectURL(blob);
      window.open(url,"_blank");
    }
  },

  datesToForm: (label :string, dateString :string) :Object => {
    if (dateString) {
      let dates = dateString.split('-');
      return {
        [`${label}-day`]: dates[2],
        [`${label}-month`]: dates[1],
        [`${label}-year`]: dates[0],
      }
    } else {
      return {}
    }
  },

  formToDates: (label :string, dateString :string) :Object => {
    if (dateString) {
      let dates = dateString.split('-');
      return {
        [`${label}-day`]: dates[0],
        [`${label}-month`]: dates[1],
        [`${label}-year`]: dates[2],
      }
    } else {
      return {}
    }
  },

  formatDob: (datestring: string, inFormat :string) :string => {
    return dayjs(datestring, inFormat).format('MMMM D, YYYY')
  },

  dedupeObjectArray: (array :Object[]) :Object[] => {
    return array.filter((v,i,a)=>a.findIndex(v2=>(JSON.stringify(v) === JSON.stringify(v2)))===i)
  },

  getTitle: (titleText :string, t :function) :string => `${titleText} | ${ t('ui.serviceTitle') }`
}
