
import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'govuk-react';
import i18n from '../../services/i18n-service';

const LanguageList = styled.ul`
  list-style-type: none;

  > li:last-child {
    border-right: none;
    padding-right: 0;
  }
`;

const LanguageListItem = styled.li`
  display: inline-block;
  margin: 0 0 10px 0;
  border-right: 1px solid #b1b4b6;
  padding: 0 10px;
`;

const Header = () => {

  const currentLang = i18n.language;

  const handleLanguageClick = (event) => {
    event.preventDefault();
    i18n.changeLanguage(event.target.attributes.href.value);
  };

  const renderLink = (lang, label) => {
    if ((currentLang === lang)) {
      return <span>{label}</span>
    } else {
      return <Link href={lang} onClick={handleLanguageClick}>{label}</Link> 
    }
  }
  
  return (
    <LanguageList>
      <LanguageListItem>
        { renderLink('en', 'English') }
      </LanguageListItem>
      <LanguageListItem>
        { renderLink('es', 'Español') }
      </LanguageListItem>
      <LanguageListItem>
        { renderLink('hi', 'हिंदी') }
      </LanguageListItem>
      <LanguageListItem>
        { renderLink('ar', 'عربى') }
      </LanguageListItem>
      <LanguageListItem>
        { renderLink('pt', 'Português') }
      </LanguageListItem>
      <LanguageListItem>
        { renderLink('bn', 'বাংলা') }
      </LanguageListItem>
    </LanguageList>
  )
}

export default Header;
